define('segura-ui/mixins/google-pageview', ['exports', 'segura-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    beforePageviewToGA: function (ga) {},

    pageviewToGA: Ember.on('didTransition', function (page, title) {
      var page = page ? page : this.get('url');
      var title = title ? title : this.get('url');

      if (Ember.get(_environment.default, 'googleAnalytics.webPropertyId') != null) {
        var trackerType = Ember.getWithDefault(_environment.default, 'googleAnalytics.tracker', 'analytics.js');

        if (trackerType === 'analytics.js') {
          var globalVariable = Ember.getWithDefault(_environment.default, 'googleAnalytics.globalVariable', 'ga');

          this.beforePageviewToGA(window[globalVariable]);

          window[globalVariable]('send', 'pageview', {
            page: page,
            title: title
          });
        } else if (trackerType === 'ga.js') {
          window._gaq.push(['_trackPageview']);
        }
      }
    })

  });
});