define("segura-ui/controllers/inicio", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {

      sendEmail() {

        this.set("valid", true);
        $("#texto-nome").css("border-color", "lightgrey");
        $("#texto-email").css("border-color", "lightgrey");
        $("#texto-conteudo").css("border-color", "lightgrey");

        if ($("#texto-nome").val() == "") {
          $("#texto-nome").css("border-color", "red");
          this.set("valid", false);
        }
        if ($("#texto-email").val() == "" || !$("#texto-email").val().includes("@") || !$("#texto-email").val().includes(".com")) {
          $("#texto-email").css("border-color", "red");
          this.set("valid", false);
        }
        if ($("#texto-conteudo").val() == "") {
          $("#texto-conteudo").css("border-color", "red");
          this.set("valid", false);
        }

        if (this.get("valid") == true) {

          Ember.$.getJSON('enviaremail.seguralarmes.com.br/email.php?nome=' + this.get('nome') + "&email=" + this.get('email') + "&conteudo=" + this.get('conteudo')).then(() => {
            $("#alerta-sucesso").fadeIn("slow");
            setTimeout(function () {
              $("#alerta-sucesso").fadeOut("slow");
            }, 5000);
          }).catch(error => {
            $("#alerta-erro").fadeIn("slow");
            setTimeout(function () {
              $("#alerta-erro").fadeOut("slow");
            }, 5000);
          });
        } else {

          $("#alerta-invalido").fadeIn("slow");
          setTimeout(function () {
            $("#alerta-invalido").fadeOut("slow");
          }, 5000);
        }
      },
      goInicio() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      goHistoria() {
        window.scrollTo({
          top: $("#historia").offset().top,
          behavior: 'smooth'
        });
      },
      goServico() {
        window.scrollTo({
          top: $("#servicos").offset().top,
          behavior: 'smooth'
        });
      },
      goSuporte() {
        window.scrollTo({
          top: $("#suporte").offset().top,
          behavior: 'smooth'
        });
      },
      goContato() {
        window.scrollTo({
          top: $("#contato").offset().top,
          behavior: 'smooth'
        });
      }
    }
  });
});