define('segura-ui/components/menu-principal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      goInicio() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      goHistoria() {
        window.scrollTo({
          top: $("#historia").offset().top,
          behavior: 'smooth'
        });
      },
      goServico() {
        window.scrollTo({
          top: $("#servicos").offset().top,
          behavior: 'smooth'
        });
      },
      goSuporte() {
        window.scrollTo({
          top: $("#suporte").offset().top,
          behavior: 'smooth'
        });
      },
      goContato() {
        window.scrollTo({
          top: $("#contato").offset().top,
          behavior: 'smooth'
        });
      }
    }
  });
});