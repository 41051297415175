define('segura-ui/router', ['exports', 'segura-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    didTransition() {
      this._super(...arguments);
      window.scrollTo(0, 0);
    }
  });

  Router.map(function () {
    this.route('inicio', { path: "/" });
  });

  exports.default = Router;
});