define('segura-ui/initializers/ember-spinner', ['exports', 'segura-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var emberSpinnerPrefix = {
    modulePrefix: _environment.default.modulePrefix
  };

  exports.default = {
    name: 'ember-spinner-prefix',

    initialize: function () {
      let application = arguments[1] || arguments[0];
      application.register('ember-spinner:main', emberSpinnerPrefix, { instantiate: false });
      application.inject('component:ember-spinner', 'emberSpinnerPrefixConfig', 'ember-spinner:main');
    }
  };
});